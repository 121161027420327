import Auth from '@aws-amplify/auth';

async function getIdToken() {
  try {
    const session = await Auth.currentSession();
    return session.idToken.jwtToken;
  } catch (err) {
    return err;
  }
}

async function getIdTokenPayload() {
  try {
    const session = await Auth.currentSession();
    return session.idToken;
  } catch (err) {
    return err;
  }
}

async function getUserId() {
  const session = await Auth.currentSession();
  return session.idToken.payload.sub;
}

async function getUserTeamId() {
  const session = await Auth.currentSession();
  return session.idToken.payload['custom:teamId'];
}

export {
  getIdToken,
  getIdTokenPayload,
  getUserId,
  getUserTeamId,
};
