import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import AppContainer from '@fathomtech/algasat-ui-components/dist/Containers/App';
import { navBar } from '../routes';
import { AppContext } from '../context/AppContext';

function Layout({
  title, signOut, user, children,
}) {
  const appContext = useContext(AppContext);
  const {
    isSnackbarOpen, closeSnackbar,
    snackbarMessage, snackbarType,
  } = appContext;

  return (
    <BrowserRouter>
      {
        user
        && (
          <AppContainer
            title={title}
            handleSignOut={signOut}
            user={user}
            isSnackbarOpen={isSnackbarOpen}
            closeSnackbar={closeSnackbar}
            snackbarMessage={snackbarMessage}
            snackbarType={snackbarType}
            navigation={navBar}
          >
            {children}
          </AppContainer>
        )
      }
    </BrowserRouter>
  );
}

Layout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  user: PropTypes.object,
  signOut: PropTypes.func,
};

export default Layout;
