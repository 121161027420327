import { React, useState } from 'react';
import Auth from '@aws-amplify/auth';
import PropTypes from 'prop-types';
import UsersContext from './UsersContext';

import { getUser, updateUser } from '../utils/api/users';

export function UsersProvider(props) {
  const [currentUser, setCurrentUser] = useState();

  const getUserDetails = async () => {
    try {
      const response = await getUser();
      const formattedUser = await Auth.currentAuthenticatedUser(({ bypassCache: true }));
      setCurrentUser(formattedUser.attributes);
      if (response.errorMessage) {
        throw new Error(response.errorMessage);
      }
      return response.item;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  const updateUserDetails = async (newUserObject) => {
    try {
      const response = await updateUser(newUserObject);
      const formattedUser = await Auth.currentAuthenticatedUser(({ bypassCache: true }));
      setCurrentUser(formattedUser.attributes);
      if (response.errorMessage) {
        throw new Error(response.errorMessage);
      }
      return response.item;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  return (
        <UsersContext.Provider
          value={{
            getUserDetails,
            updateUserDetails,
            currentUser,
            setCurrentUser,
          }}
        >
          {props.children}
        </UsersContext.Provider>
  );
}

UsersProvider.propTypes = {
  appContext: PropTypes.object,
  children: PropTypes.node,
};

export default UsersProvider;
