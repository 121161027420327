const secondary = {
  50: '#F3E5F5',
  100: '#E1BEE7',
  200: '#CE93D8',
  300: '#AB47BC',
  400: '#AB47BC',
  500: '#9C27B0',
  600: '#8E24AA',
  700: '#7B1FA2',
  800: '#6A1B9A',
  900: '#4A148C',
  A100: '#EA80FC',
  A200: '#D500F9',
  A400: '#D500F9',
  A700: '#AA00FF',
};

export default secondary;
