import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AppContext } from './AppContext';

export const AppContextProvider = ({ children }) => {
  const [user, setCurrentUser] = useState({});
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('info');
  const [isMobile, setIsMobile] = useState(false);
  const [bottomNavigationValue, setBottomNavigationValue] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setIsMobile(true);
    }
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const openSidebar = () => {
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const closeSnackbar = () => {
    setIsSnackbarOpen(false);
  };

  const openSnackbar = (messageParam, typeParam) => {
    setSnackbarMessage(messageParam);
    setSnackbarType(typeParam);
    setIsSnackbarOpen(true);
  };

  const handleBottomNavChange = (event, value) => {
    setBottomNavigationValue(value);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
    setIsMobileMenuOpen(true);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
    setIsMobileMenuOpen(false);
  };

  const setUser = (usr) => {
    setCurrentUser(usr);
  };

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        isSidebarOpen,
        isMobileMenuOpen,
        isSnackbarOpen,
        snackbarMessage,
        snackbarType,
        isMobile,
        bottomNavigationValue,
        mobileMoreAnchorEl,
        toggleSidebar,
        openSnackbar,
        closeSnackbar,
        closeSidebar,
        openSidebar,
        handleBottomNavChange,
        handleMobileMenuClose,
        handleMobileMenuOpen,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  appContext: PropTypes.object,
  children: PropTypes.node,
};

export default AppContextProvider;
