import React from 'react';
import {
  Assignment as DashboardIcon,
  Group as TeamIcon,
  Settings as SettingsIcon,
  ContactSupport as ContactIcon,
} from '@mui/icons-material';
import config from './config';

const Dashboard = React.lazy(() => import('./views/Dashboard'));
const Report = React.lazy(() => import('./views/Report'));
const Team = React.lazy(() => import('./views/Team'));
const Settings = React.lazy(() => import('./views/Settings'));

const routes = [
  { path: '/dashboard', name: 'Dashboard', component: <Dashboard /> },
  { path: '/results/:teamId/:jobRef', name: 'Report', component: <Report /> },
  { path: '/team', name: 'Team', component: <Team /> },
  { path: '/settings', name: 'Settings', component: <Settings /> },
];

export const navBar = [
  {
    url: '/dashboard',
    name: 'Dashboard',
    icon: <DashboardIcon />,
  },
  {
    url: '/team',
    name: 'Team',
    icon: <TeamIcon />,
  },
  {
    url: '/settings',
    name: 'Settings',
    icon: <SettingsIcon />,
  },
  {
    divider: true,
  },
  {
    url: `https://${config.RouteDomain}/how-it-works/the-ml-model/`,
    name: 'Model Performance',
    icon: <ContactIcon />,
    external: true,
  },
  {
    url: `https://${config.RouteDomain}/how-it-works/prediction-pipeline/`,
    name: 'Prediction Pipeline',
    icon: <ContactIcon />,
    external: true,
  },
];

export default routes;
