import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from '@aws-amplify/core';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import config from './config';
import themes from './themes/Material-UI';

import { UsersProvider } from './context/UsersContextProvider';
import { AppContextProvider } from './context/AppContextProvider';

const { cognito } = config;

Amplify.configure({
  Auth: cognito,
});

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={themes}>
      <React.StrictMode>
        <AppContextProvider>
          <UsersProvider>
            <App />
          </UsersProvider>
        </AppContextProvider>
      </React.StrictMode>
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
