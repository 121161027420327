import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import {
  CircularProgress, Grid, Box, Paper,
} from '@mui/material';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
import Logo from './images/logo_green.png';
import SplashImage from './images/BGImage.jpg';
import Layout from './Layout';
import routes from './routes';
import config from './config';
import './App.css';

// eslint-disable-next-line no-unused-vars
const { cognito: { authEnabled } } = config;

const components = {
  Header() {
    return (
      <Box>
        <Box
          component="div"
          sx={{
            textAlign: 'center',
          }}
        >
          <Box
            component="img"
            sx={{
              maxHeight: { xs: 233, md: 167 },
              maxWidth: { xs: 350, md: 250 },
            }}
            alt="Fathom Logo."
            src={Logo}
          />
        </Box>
      </Box>
    );
  },
};

function Home() {
  const loading = () => <CircularProgress/>;
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  return (
    <Layout user={user.attributes} signOut={signOut}>
      <Suspense fallback={loading()}>
        <Routes>
          {routes.map((route, idx) => (route.component ? (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              name={route.name}
              element={route.component}
            />
          ) : (null)))}
          <Route path="/" element={<Navigate replace to="/dashboard" />} />
        </Routes>
      </Suspense>
    </Layout>
  );
}

function Login() {
  const loading = () => <CircularProgress />;
  return (
    <Suspense fallback={loading()}>
      <Grid container spacing={0} height="100vh">
        <Grid item sm={4} md={7} sx={{
          backgroundImage: `url(${SplashImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}>
        </Grid>
        <Grid item xs={12} sm={8} md={5} padding={0}>
          <Paper elevation={18} sx={{
            minHeight: '100vh',
            padding: '1.5rem',
            marginTop: 0,
          }}>
            <Box sx={{ height: '100px' }}/>
            <Authenticator hideSignUp={true} components={components} />
          </Paper>
        </Grid>
      </Grid>
    </Suspense>
  );
}

function App() {
  const { route } = useAuthenticator((context) => [context.route]);
  return route === 'authenticated' ? <Home /> : <Login />;
}

function AppWithProvider() {
  return (
    <Authenticator.Provider>
      <App></App>
    </Authenticator.Provider>
  );
}

// const appExport = authEnabled ? withAuth(App) : App;

export default AppWithProvider;
