import { createContext } from 'react';

export const AppContext = createContext({
  user: {},
  setUser: () => {},
  isSidebarOpen: false,
  isMobileMenuOpen: false,
  isSnackbarOpen: false,
  snackbarMessage: '',
  snackbarType: '',
  isMobile: false,
  bottomNavigationValue: '',
  mobileMoreAnchorEl: '',
  toggleSidebar: () => {},
  openSnackbar: () => {},
  closeSidebar: () => {},
  openSidebar: () => {},
  closeSnackbar: () => {},
  handleBottomNavChange: () => {},
  handleMobileMenuClose: () => {},
  handleMobileMenuOpen: () => {},
});

export const AppContextConsumer = AppContext.Consumer;
