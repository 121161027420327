import { createTheme } from '@mui/material/styles';
import { green, red } from '@mui/material/colors';
import primary from './colors/primary';
import secondary from './colors/secondary';

export const theme = {
  palette: {
    type: 'light',
    primary: {
      light: primary[500],
      main: primary[700],
      dark: primary[900],
    },
    secondary,
    success: {
      light: green[300],
      main: green[500],
      dark: green[700],
    },
    danger: {
      light: red[300],
      main: red[500],
      dark: red[700],
    },
    text: {
      primary: '#212353',
    },
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: 16,
    textTransform: 'none',
  },
  overrides: {
  },
};

const muiTheme = createTheme(theme);
export default muiTheme;
