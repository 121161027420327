import config from '../../config';
import {
  getIdToken,
  getUserId,
} from '../userAuth';

const API_DOMAIN = config.APIDomain;
const ENDPOINT = 'users';

// eslint-disable-next-line import/prefer-default-export
export const getUser = async () => {
  try {
    const token = await getIdToken();
    const userId = await getUserId();

    const response = await fetch(
      `${API_DOMAIN}/${ENDPOINT}/${userId}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    );

    if (response.status === 200) {
      const parsedResponse = await response.json();
      return parsedResponse;
    }

    throw response;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const updateUser = async (newUserObject) => {
  try {
    const token = await getIdToken();
    const userId = await getUserId();

    console.log(JSON.stringify(newUserObject));

    const response = await fetch(
      `${API_DOMAIN}/${ENDPOINT}/${userId}`,
      {
        method: 'PATCH',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(newUserObject),
      },
    );

    if (response.status === 200) {
      const parsedResponse = await response.json();
      return parsedResponse;
    }

    throw response;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
